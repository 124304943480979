
.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 60px auto;
    width: 95%;
}
.flex-left-container {
    flex: 30%;
}

.flex-right-container {
    flex: 70%;
}

.flex-left-item {
    background-color: #293377;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0% 3%;
}

.flex-left-item-logo {
    display: flex; 
    flex-direction: row; 
    justify-content: center;
    padding: 2% 0%;
}

@media  (max-width:768px){
    .flex-container {
        flex-direction: column;
    }
    .flex-left-container{
        height: 100px;
    }
    .flex-left-item-logo {
        justify-content: left;
    }
}
@media screen and (min-width: 769px) {
    .flex-container {
        flex-direction: row;
        justify-content: stretch;
        width: 80%;

    }
    .flex-left-item-logo{
        justify-content: left;
    }
}
@media screen and (min-width: 912px) {
    .flex-container{
        flex-direction: row;
        width: 70%;
    }
}


@media screen and (min-width:540px) {

}


@media  (min-width:1000px){
    .flex-container {
        width: 65%;
    }
}

@media  (min-width:1100px){
    .flex-container {
        width: 60%;
    }
}

@media  (min-width:1440px){
    .flex-container {
        width: 45%;
    }
}

@media  (min-width:1800px){
    .flex-container {
        width: 40%;
    }
}

@media  (min-width:2000px){
    .flex-container {
        width: 35%;
    }
}

.confirm-btn{
    width:90% !important
}
@media (max-device-width:425px) {
    .confirm-btn{
        width:100% !important
    }
}