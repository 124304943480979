input {
    width: 90%;
    box-sizing: border-box;
    /* border: none; */
    border-color: #eee;
    border-radius: 4px;
    font-size: 16px;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 10px 11px;
    background-color: #f6f6f6;
    padding: 10px 20px 10px 36px;
    /* transition: width 0.4s ease-in-out; */
}
.input-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input-pw {
    background-image: url('../../../assests/passwordicon.svg');
    width: 90%;    
}

.input-txt {
    background-image: url('../../../assests/usericon.svg');
    width: 90%;    
}


@media screen and (max-device-width:425px) {
    .input-pw {
        background-image: url('../../../assests/passwordicon.svg');
        width: 85%;    
    }
}

@media screen and (max-width: 500px) {
    input, .input-pw, .input-txt {
        background-size: 15px;
        padding: 5px 20px 5px 30px;
        background-position: 10px 8px;
        width: 90%;
    }
}